import { defineStore } from 'pinia';
import type { DownloadCategoryDto } from '@containex/portal-backend-dto';

interface DownloadCategoriesState {
    downloadCategories: DownloadCategoryDto[];
}

export const useDownloadCategoriesStore = defineStore('downloadCategories', {
    state: (): DownloadCategoriesState => ({
        downloadCategories: [],
    }),
});
