import { computed, type ComputedRef } from 'vue';
import { useDownloadCategoriesStore } from '@/account/stores/downloads';
import type { DownloadCategoryDto } from '@containex/portal-backend-dto';
import { downloadApi } from '@containex/portal-backend-api-client';
import useGlobalToast from '@/composables/useGlobalToast';
import { getLogger } from '@/logger/logger';
import { httpClient } from '@/common/api/http-client';

export interface DownloadCategoriesQuery {
    downloadCategories: ComputedRef<DownloadCategoryDto[]>;
}

export interface DownloadCategoriesAction {
    fetchDownloadCategories(selectedLanguage: string): Promise<void>;
}

export function useDownloadCategoriesQuery(): DownloadCategoriesQuery {
    const store = useDownloadCategoriesStore();

    return {
        downloadCategories: computed(() => store.downloadCategories),
    };
}

export function useDownloadCategoriesAction(): DownloadCategoriesAction {
    const store = useDownloadCategoriesStore();
    const { errorToastOptions, addToast } = useGlobalToast();
    const logger = getLogger('DownloadCategoriesAction');

    return {
        async fetchDownloadCategories(selectedLanguage: string): Promise<void> {
            try {
                const response = await downloadApi.fetchDownloadCategories(httpClient, selectedLanguage);

                store.downloadCategories = response.data.downloadCategories;
            } catch (error) {
                logger.warn(error, 'Fetching download categories failed');

                store.downloadCategories = [];

                addToast(errorToastOptions);
            }
        },
    };
}
